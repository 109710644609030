import React from 'react'

import { useState, useEffect, useContext } from 'react'
import PopupContext from '../context/popupContext'
import axios from 'axios'

import { v4 } from 'uuid'

import './Form.scss'
import './Button.scss'

import CryptoJS from 'crypto-js' // Импортируем библиотеку для хеширования

function Form() {
    const [nameState, setNameState] = useState()
    const [phoneState, setPhoneState] = useState()

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    function nameChangeHandler(text) {
        setNameState(text)
    }

    function phoneChangeHandler(phone) {
        setPhoneState(phone)
    }

    const [fullForm, setFullForm] = useState('')

    useEffect(() => {
        const message = `<b>Новая заявка от</b> ${nameState}%0A<b>Номер телефона:</b> ${phoneState}`
        setFullForm(message)
    }, [nameState, phoneState])

    const chatID = process.env.REACT_APP_CHAT_ID
    const botToken = process.env.REACT_APP_BOT_TOKEN

    const CAPIToken = process.env.REACT_APP_CAPI_TOKEN
    const pixelID = process.env.REACT_APP_PIXEL_ID
    const APIVersion = process.env.REACT_APP_API_VERSION

    function handleSubmit(evt) {
        evt.preventDefault()
        // if (!captchaValue) {
        //     alert('Подтвердите, что вы не робот')
        //     return
        // }

        console.log(fullForm)

        // const botUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatID}&parse_mode=html&text=${fullForm}`
        // // axios.post(botUrl, 'message').then((response) => console.log(response.status))



        const hashedName = CryptoJS.SHA256(nameState.toLowerCase().trim()).toString()
        const hashedPhone = CryptoJS.SHA256(phoneState.replace(/\D/g, '')).toString() // Удаляем все нецифровые символы для телефона

        const data = {
            data: [
                {
                    event_name: 'Lead',
                    event_time: Math.floor(Date.now() / 1000),
                    action_source: 'website',
                    event_id: Math.ceil(Math.random()*10000),
                    user_data: {
                        fn: hashedName,
                        ph: hashedPhone,
                        client_ip_address: '254.254.254.254',
                        client_user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:63.0) Gecko/20100101 Firefox/63.0',
                        em: 'f660ab912ec121d1b1e928a0bb4bc61b15f5ad44d5efdc4e1c92a25e99b8e44a',
                    },
                },
            ],
        }

       

        const CAPIURL = `https://graph.facebook.com/v20.0/${pixelID}/events?access_token=${CAPIToken}`
        console.log(CAPIURL)
        axios.post(CAPIURL, data).then((response) => console.log(response))

        handleBackdropClick()
    }

    // console.log(Math.ceil(Math.random()*10000))

    // console.log(`https://graph.facebook.com/v20.0/${pixelID}/events?access_token=${CAPIToken}`)

    return (
        <form className="form-contacts" onSubmit={handleSubmit}>
            <div className="header-style" style={{ fontSize: '2rem' }}>
                Оставьте номер и мы вас проконсультируем
            </div>

            <div className="input-container">
                <label htmlFor="name">
                    Ваше имя
                    <input type="text" id="name" name="name" onChange={(evt) => nameChangeHandler(evt.target.value)} />
                </label>
            </div>

            <div className="input-container">
                <label htmlFor="phone">
                    Ваш номер телефона
                    <input type="tel" id="phone" name="phone" onChange={(evt) => phoneChangeHandler(evt.target.value)} required />
                </label>
            </div>

            <button type="submit" className="btn btn--white btn--white--bd-black">
                Свяжитесь со мной
            </button>
        </form>
    )
}

export default Form
